import type { InitAppForPageFn } from '@wix/yoshi-flow-editor';
import { configure } from 'mobx';

import { ProfileSubject } from './services/profile-subject';
import type { ProfileCardAPI } from './types';

configure({ isolateGlobalState: true });

const profileSubject = new ProfileSubject();

export const initAppForPage: InitAppForPageFn = async () => ({
  profileSubject,
});

export const exports = (): ProfileCardAPI => ({
  registerToProfileChange: (callback) => {
    profileSubject.registerObserver(callback);
  },
});
